import * as React from 'react'
import Layout from './../components/Layout'
import tw, { styled } from 'twin.macro'
import { Helmet } from "react-helmet"

// SVG
import LogoImage from './../images/svg/ctLogo.inline.svg'
import LogoWhiteImage from './../images/svg/logo-white.inline.svg'
import ConstructionHat from './../images/svg/constructionHat.inline.svg'
import ConstructionSourceImage from './../images/svg/bgWarning.inline.svg'

// Global
import { createGlobalStyle } from 'styled-components';

// Styles

const AnimatedFlowing = styled.div`
  background: linear-gradient(270deg, #9ae082, #82bce0);
  background-size: 400% 400%;

  -webkit-animation: ct-flow 20s ease infinite;
  animation: ct-flow 20s ease infinite;

  @-webkit-keyframes ct-flow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @keyframes ct-flow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
`
const SiteWrapper = tw.div`flex flex-col min-h-screen justify-between`

const Header = styled(AnimatedFlowing)`
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 447.45 185.11" preserveAspectRatio="none"><path  d="M0,0H447.45V164.8c-13.55-1.63-27-5.36-40.45-8-76.86-14.8-154.93,7.8-232.07,20.72-58,9.71-120.49,12.8-174.93-7.72Z"/></svg>');
}

${tw`mb-12 h-24 flex items-center xl:mb-16`}
`

const Content = tw.main`container mb-auto mx-auto grid grid-cols-12 gap-y-8 md:(gap-y-8) xl:(gap-y-0)`

const Footer = tw.div`h-10 mt-12 relative xl:hidden`
const FooterAnimation = styled(AnimatedFlowing)`
mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 447.45 77.91" preserveAspectRatio="none"><path d="M0,77.91H447.45V8.55c-13.55.68-27,2.25-40.45,3.35-76.86,6.22-154.93-3.29-232.07-8.73C116.94-.91,54.44-2.21,0,6.42Z"/></svg>');

${tw`h-full`}
`
const ConstructionElement = tw(ConstructionHat)`absolute right-4 bottom-4 md:hidden`
const IconLogo = tw(LogoWhiteImage)`h-14 margin-left[15%]`

const StandardText = tw.div`text-2xl max-width[400px]`
const StandardList = tw.ul`text-xl`
const StandardElement = tw.div`col-span-full`

const LogoWrapper = tw(StandardElement)`inline-block pb-0 md:border-0 xl:(pb-8 col-span-4 row-start-2 col-start-1 border-r-2)`
const Logo = tw(LogoImage)`h-20 px-4 w-full`

const InformationWrapper = tw.div`items-center px-4 grid gap-y-6 grid-cols-12 col-span-12 md:(gap-x-4) xl:(gap-x-0 col-span-4 row-span-2 row-start-3 border-r-2)`
const Message = tw(StandardText)`font-medium md:(mx-0 ml-auto) xl:(mx-0 mr-auto) 
    col-span-full md:(col-span-4 col-start-3) xl:(col-span-12)`
const Contact = tw(StandardList)`h-full pl-4 border-l-4 flex justify-center flex-col md:(mx-auto) xl:(mx-0 mr-auto)
    col-span-full md:(col-span-4) xl:(col-span-12)`
const ContactElement = tw.li``

const ConstructionWrapper = tw(StandardElement)`hidden md:(flex items-center justify-center) xl:(col-span-8 row-span-5)`
const ConstructionImage = tw(ConstructionSourceImage)`px-8 `

const GridDividerCap = tw(StandardElement)`hidden border-r-2 col-span-4 py-0 my-0 h-14 xl:block`

const App = () => {
  return(
    <Layout>
      <Helmet>
        <title>CleanTech - Dämm- und Wandbaustoffe</title>
        <meta name="description" content="Dies ist die temporäre Webseite der CleanTech Dämm- und Wandbaustoffe!" />
        <meta name="keywords" content="Dämmung, Wandbaustoffe, Cleantech, Clean" />
        <meta name="author" content="Roman Sajzew" />
      </Helmet>
      <SiteWrapper>
        <Header>
          <IconLogo />
        </Header>
        <Content>
          <GridDividerCap tw="self-end"/>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <InformationWrapper>
            <Message>
              Hier entsteht der&nbsp;Internetauftritt der&nbsp;CleanTech Dämm- und Wandbaustoffe
            </Message>
            <Contact>
              <ContactElement>info@ct-mat.de</ContactElement>
              <ContactElement>Breite Weg 38</ContactElement>
              <ContactElement>76547 Sinzheim</ContactElement>
              <ContactElement><span style={{display: 'inline-block', width: "3.2ch"}}>Tel:</span> 07221 3936478</ContactElement>
              <ContactElement><span style={{display: 'inline-block', width: "3.2ch"}}>Fax:</span> 07221 3936479</ContactElement>
            </Contact>
          </InformationWrapper>
          <GridDividerCap tw="row-start-5 col-start-1"/>
          <ConstructionWrapper>
            <ConstructionImage />
          </ConstructionWrapper>
        </Content>
        <Footer>
          <FooterAnimation />
          <ConstructionElement />
        </Footer>
      </SiteWrapper>
    </Layout>
  )
}

export default App